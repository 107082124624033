import { useEffect, useState } from 'react';
import { util } from '@alipay/Lolita';
import useDowngrade from '@alipay/useDowngrade';
import useLolita, { UseLolitaProps, LolitaController } from './useLolita';
import DowngradeLolita from './DowngradeLolita';

const { logger } = util;
export interface UseDowngradeLolitaProps extends UseLolitaProps {
  needDowngrade?: boolean;
  downgradeBizId?: string;
  downgradeFrame?: number;
}

const useDowngradeLolita = (
  controller: LolitaController,
  { needDowngrade = false, downgradeBizId, downgradeFrame, path, ...rest }: UseDowngradeLolitaProps,
  deps: any[] = [],
) => {
  const { downgradeType } = useDowngrade(
    needDowngrade ? { bizId: downgradeBizId, techPoint: 'lottieWeb' } : {},
  );
  const [lolitaPath, setLolitaPath] = useState<string>(); // 要真正加载 lottie 时设置此 path

  // 根据降级结果初始化
  useEffect(() => {
    if (!path) {
      return;
    }
    const init = async () => {
      if (downgradeType !== 'loading') {
        logger.info(
          `[useDowngadeLolita] needDowngrade: ${needDowngrade}, downgradeType: ${downgradeType}, downgradeFrame: ${downgradeFrame}`,
        );
        setLolitaPath(path);
      }
    };

    init();
  }, [downgradeType, path]);

  // 分派给 useLolita 处理
  const { ref, lolitaRef } = useLolita(
    (lolita) => {
      // note: 当 play/replace 等变化时，会重新执行 controller，但 lolita 实例上的属性（降级相关属性）依然是旧值，这里做一下传递赋值
      const downgradeLolita = lolita as DowngradeLolita;
      downgradeLolita.restProps.downgradeFrame = downgradeFrame;
      downgradeLolita.downgradeFrame = downgradeFrame;

      controller(downgradeLolita);
    },
    {
      path: lolitaPath,
      LolitaClass: DowngradeLolita,
      downgradeType,
      downgradeFrame,
      ...rest,
    },
    deps, // deps 目前是 [play, replaceData, onLayerClick]
  );

  return { ref, downgradeType, lolitaRef };
};

export default useDowngradeLolita;
