import { useEffect, useRef, useCallback, useReducer } from 'react';
import Lolita, { util } from '@alipay/Lolita';

const { logger } = util;

export interface UseLolitaProps {
  path?: string;
  animationData?: any;
  LolitaClass?: typeof Lolita; // 指定 Lolita 的类
  [name: string]: any; // 支持传入其他数据，用于构造 lolita 对象
}

export interface LolitaController {
  (lolita: Lolita): void;
}

const useLolita = (
  controller: LolitaController,
  { path, animationData, LolitaClass = Lolita, ...rest }: UseLolitaProps,
  deps: any[] = [],
) => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0); // 解决 ref 更新不触发 useEffect 的问题
  const ref = useRef<HTMLDivElement | null>(null);
  const lolitaRef = useRef<Lolita | null>(null);

  const destroyLolita = () => {
    if (!lolitaRef.current) {
      return;
    }
    lolitaRef.current.removeLayerClickListeners();
    lolitaRef.current.destroy();
    lolitaRef.current = null;
    logger.info('[useLolita] destroy lolita');
  };

  // ref: https://medium.com/@teh_builder/ref-objects-inside-useeffect-hooks-eb7c15198780
  const setRef = useCallback((node: HTMLDivElement) => {
    if (ref.current) {
      destroyLolita();
    }
    ref.current = node;
    forceUpdate();
  }, []);

  useEffect(() => {
    if (!ref.current || (!path && !animationData)) {
      return;
    }
    lolitaRef.current = LolitaClass.loadAnimation({
      container: ref.current,
      path,
      animationData,
      ...rest, // downgradeFrame 等属性
    });
    forceUpdate();
    logger.info('[useLolita] build lolita');

    return () => {
      destroyLolita();
    };
  }, [ref.current, path, animationData]);

  // 指定的依赖变更时，触发 controller 执行
  useEffect(() => {
    if (!lolitaRef.current) {
      return;
    }
    controller(lolitaRef.current);
    logger.info('[useLolita] run controller');
  }, [lolitaRef.current, ...deps]); // deps 目前是 [play, replaceData, onLayerClick]

  return { ref: setRef, lolitaRef };
};

export default useLolita;
