/**
 * 对 ReactLolita 的 memo 优化
 */
import {
  PlayOption,
  PlayOptionType,
  SimpleReplaceOption,
  ReplaceOptionType,
  util,
} from '@alipay/Lolita';
import { ReactLolitaProps, LayerClickOptions } from './types';

const { logger } = util;

function arePlayEqual(prevPlay: PlayOptionType, nextPlay: PlayOptionType): boolean {
  if (nextPlay === null || prevPlay === null || nextPlay === undefined || prevPlay === undefined) {
    return nextPlay === prevPlay;
  }
  if (typeof nextPlay === 'string' && typeof prevPlay === 'string') {
    return nextPlay === prevPlay;
  }
  if (Array.isArray(nextPlay) && Array.isArray(prevPlay)) {
    if (nextPlay.length === prevPlay.length) {
      return nextPlay.every((play, index) => {
        return arePlayEqual(play, prevPlay[index]);
      });
    }
  }
  if (
    typeof nextPlay === 'object' &&
    'name' in (nextPlay as PlayOption | PlayOption[]) &&
    typeof prevPlay === 'object' &&
    'name' in (prevPlay as PlayOption | PlayOption[])
  ) {
    return (
      (nextPlay as PlayOption).name === (prevPlay as PlayOption).name &&
      (nextPlay as PlayOption).playCount === (prevPlay as PlayOption).playCount &&
      (nextPlay as PlayOption).speed === (prevPlay as PlayOption).speed &&
      (nextPlay as PlayOption).direction === (prevPlay as PlayOption).direction &&
      (nextPlay as PlayOption).onStart === (prevPlay as PlayOption).onStart &&
      (nextPlay as PlayOption).onComplete === (prevPlay as PlayOption).onComplete
    );
  }
  return false;
}

function areReplaceEqual(prevReplace: ReplaceOptionType, nextReplace: ReplaceOptionType): boolean {
  if (Array.isArray(nextReplace) && Array.isArray(prevReplace)) {
    if (nextReplace.length === prevReplace.length) {
      return nextReplace.every((replace, index) => {
        return (
          replace.name === prevReplace[index].name &&
          replace.value === prevReplace[index].value &&
          replace.path === prevReplace[index].path
        );
      });
    }
  } else {
    const nextKeys = Object.keys(nextReplace || {});
    const prevKeys = Object.keys(prevReplace || {});
    if (nextKeys.length === prevKeys.length) {
      if (nextKeys.length === 0) {
        return true;
      }
      return nextKeys.every((key: string) => {
        return (
          (prevReplace as SimpleReplaceOption)[key] === (nextReplace as SimpleReplaceOption)[key]
        );
      });
    }
  }

  return false;
}

function areOnLayerClickEqual(prev?: LayerClickOptions, next?: LayerClickOptions): boolean {
  if (next === prev) {
    return true;
  }
  if (next === null || prev === null || next === undefined || prev === undefined) {
    return next === prev;
  }
  const nextKeys = Object.keys(next);
  const prevKeys = Object.keys(prev);
  if (nextKeys.length === prevKeys.length) {
    if (nextKeys.length === 0) {
      return true;
    }
    return nextKeys.every((key: string) => {
      return prev[key] === next[key];
    });
  }
  return false;
}

export default function areEqual(prevProps: ReactLolitaProps, nextProps: ReactLolitaProps) {
  if (
    nextProps.path === prevProps.path &&
    nextProps.controller === prevProps.controller &&
    nextProps.pause === prevProps.pause &&
    nextProps.onError === prevProps.onError &&
    areOnLayerClickEqual(prevProps.onLayerClick, nextProps.onLayerClick) &&
    arePlayEqual(prevProps.play, nextProps.play) &&
    areReplaceEqual(prevProps.replaceData, nextProps.replaceData)
  ) {
    logger.debug('[ReactLolita] memorized');
    return true;
  }
  return false;
}
