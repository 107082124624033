import React, { memo, useEffect } from 'react';
import Lolita, { util, LogLevel } from '@alipay/Lolita';
import useDowngradeLolita from './useDowngradeLolita';
import areEqual from './memo';
import { ReactLolitaProps } from './types';
import getDataAttrs from './getDataAttrs';

const { logger } = util;

const ReactLolita: React.FC<ReactLolitaProps> = ({
  path,
  controller,
  play,
  replaceData,
  pause,
  children,
  downgradeBizId = 'lottieWebPlayer',
  downgradeFrame,
  disableDurationWhenDowngrade = false,
  onLayerClick,
  className,
  style,
  onError,
  onLoad,
  ...props
}: ReactLolitaProps) => {
  // 有子元素或者指定了 downgradeFrame 时才会查询降级
  const needDowngrade = !!children || downgradeFrame != null;

  const { ref, downgradeType, lolitaRef } = useDowngradeLolita(
    // controller
    async (lolita) => {
      if (controller) {
        // 如果自定义了 controller 那么交给他处理
        controller(lolita);
        logger.debug('[ReactLolita] 自定义 controller');
        return;
      }
      if (onError) {
        lolita.on('error', onError);
      }
      if (onLoad) {
        lolita.on('AnimationLoaded', onLoad);
      }
      if (onLayerClick) {
        lolita.removeLayerClickListeners();
        await lolita.addLayerClickListener(onLayerClick);
      }
      if (replaceData) {
        await lolita.replace(replaceData);
      }
      if (!play) {
        // 无 play 参数时默认循环播放 ALL
        lolita.playAnimation({
          name: 'ALL',
          playCount: 0,
        });
      } else {
        lolita.playAnimation(play);
      }
      // 会先执行 controller 后执行 useEffects(pause)，所以这里先做处理
      if (pause) {
        lolita.pause();
      }
    },
    {
      path,
      needDowngrade,
      downgradeBizId,
      downgradeFrame,
      disableDurationWhenDowngrade,
      onLayerClick,
    },
    [play, replaceData, onLayerClick], // 触发 controller 执行的 deps
  );

  useEffect(() => {
    if (!lolitaRef.current) {
      return;
    }
    if (pause) {
      lolitaRef.current.anim?.pause();
    } else {
      lolitaRef.current.anim?.play();
    }
  }, [pause]);

  useEffect(() => {
    window?.Tracert?.expo('ca10542', 'up', {
      lottiepath: path,
    });
  }, []);

  const dataProps = getDataAttrs(props);

  return (
    <div className={className} style={style} ref={ref} {...dataProps}>
      {downgradeType === 'downgrade' && downgradeFrame == null ? children : null}
    </div>
  );
};

// 支持定义日志级别
function setLogLevel(newValue: LogLevel) {
  Lolita.logLevel = newValue;
}

const useLolita = useDowngradeLolita;

const ReactLolitaMemo = memo(ReactLolita, areEqual);

// 兼容 umd 格式导出
// @ts-ignore
ReactLolitaMemo.useLolita = useLolita;
// @ts-ignore
ReactLolitaMemo.setLogLevel = setLogLevel;

export { setLogLevel, useLolita, LogLevel };
export default ReactLolitaMemo;
