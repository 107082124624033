import Lolita, {
  PlayOptionType,
  PlaySegmentsOption,
  ReplaceOptionType,
  util,
} from '@alipay/Lolita';
import { DowngradeType } from '@alipay/useDowngrade';

const { fillPlayOptions } = util;

const delay = (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};

// note: 为什么这里要申明 interface 而不作为 private field 呢？
// 因为 ts 之前 class field 的实现没有遵循 es 规范，子类构造时不会初始化这些 class field，但是 babel-typescript 遵循了，导致 umd/esm 的编译结果行为不一致
// 在可以使用 ts declare field 前通过 interface 折衷解决
// see: https://github.com/babel/babel/issues/9105
interface DowngradeLolita {
  downgradeType?: DowngradeType;
  downgradeFrame?: number;
  currentPlayConfig?: PlayOptionType;
  disableDurationWhenDowngrade?: boolean;
}

// 通过继承覆盖 Lolita 一些子方法，实现对降级的处理
class DowngradeLolita extends Lolita { // eslint-disable-line
  initProps() {
    super.initProps();
    this.downgradeType = this.restProps?.downgradeType;
    this.downgradeFrame = this.restProps?.downgradeFrame;
    this.disableDurationWhenDowngrade = this.restProps?.disableDurationWhenDowngrade;
  }

  initAnim() {
    if (this.downgradeType === 'downgrade') {
      if (this.downgradeFrame == null) {
        // 子元素降级，留空此方法，不构造 lottie-web 对象
      } else {
        // 单帧降级，依然需要构造
        super.initAnim();
      }
    } else if (this.downgradeType === 'normal') {
      super.initAnim();
    }
  }

  async playAnimation(config: PlayOptionType) {
    if (this.downgradeType === 'downgrade') {
      if (this.downgradeFrame == null) {
        // 子元素降级
        await this.ready('DataLoaded');
      } else {
        // 单帧降级
        await this.ready('AnimationLoaded');
        await this.goToAndStop(this.downgradeFrame, true);
      }
      this.currentPlayConfig = config;

      // 假播放实现，只是为了执行相应的回调函数
      const data = fillPlayOptions(config, this.animations, this.animationData.fr);
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        item.onStart && item.onStart();
        if (!this.disableDurationWhenDowngrade) {
          await delay(item.duration);
        }
        if (this.currentPlayConfig !== config) {
          break;
        }
        item.onComplete && item.onComplete();
      }
    } else if (this.downgradeType === 'normal') {
      return super.playAnimation(config);
    }
  }

  async playSegments({ start, end, speed, direction }: PlaySegmentsOption): Promise<void> {
    if (this.downgradeType === 'downgrade') {
      if (this.downgradeFrame == null) {
        // 子元素降级
        await this.ready('DataLoaded');
      } else {
        // 单帧降级
        await this.ready('AnimationLoaded');
        await this.goToAndStop(this.downgradeFrame, true);
      }
    } else if (this.downgradeType === 'normal') {
      return super.playSegments({ start, end, speed, direction });
    }
  }

  async replace(config: ReplaceOptionType) {
    if (this.downgradeType === 'downgrade') {
      if (this.downgradeFrame == null) {
        // 子元素降级时留空
      } else {
        // 单帧降级
        return super.replace(config);
      }
    } else if (this.downgradeType === 'normal') {
      return super.replace(config);
    }
  }
}

export default DowngradeLolita;
